import { ProfessionalCareer, AcademicCareer } from "../../../Data";
import "./Career.css";

export default function Career() {
  return (
    <>
      <div>
        <div className="flex" style={{ marginTop: "50px" }} id="career">
          <div className="flex Column">
            <p className="Tag">💼 Career</p>
            <h1 className="primary">Trajectory so far</h1>
          </div>
        </div>
        <div
          className="flex"
          style={{
            flexWrap: "wrap",
            justifyContent: "space-evenly",
            alignItems: "stretch",
          }}
        >
          <div className=" flex professionalareacontainer Column">
            <h2 className="primary">Professional Area</h2>
            <p className="tertiary">2018 - currently</p>

            {ProfessionalCareer.map((names, key) => {
              return (
                <div className="professionalcard" key={key}>
                  <h4 className="primary">{names.Title}</h4>
                  <p className="tertiary">{names.Description}</p>
                  <div
                    className="flex"
                    style={{ justifyContent: "space-around" }}
                  >
                    <p className="secondary">{names.FinishTime}</p>
                    <p className="secondary">{names.StartDate}</p>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="flex academicareacontainer Column">
            <h2 className="primary">Academic Area</h2>
            <p className="tertiary">2018 - currently</p>

            {AcademicCareer.map((names, key) => {
              return (
                <div className="academiccard" key={key}>
                  <h4 className="primary">{names.Title}</h4>
                  <p className="tertiary">{names.Description}</p>
                  <div
                    className="flex"
                    style={{ justifyContent: "space-around" }}
                  >
                    <p className="secondary">{names.FinishTime}</p>
                    <p className="secondary">{names.StartDate}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
