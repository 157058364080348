import REACT from "../../../assets/React.svg";
import HTML from "../../../assets/HTML5.svg";
import MONGO from "../../../assets/mongo.svg";
import PYTHON from "../../../assets/Python.svg";
import FIGMA from "../../../assets/Figma.svg";
import JAVASCRIPT from "../../../assets/Javascript.svg";
import GITHUB from "../../../assets/Github2.svg";
import SQL from "../../../assets/sql.svg";
import NODE from "../../../assets/Nodejs.svg";
import JAVA from "../../../assets/javaIcon.svg";
import GIT from "../../../assets/GIT.svg";
import TYPESCRIPT from "../../../assets/TypeScript.svg";
export default function TechnologyUsed() {
  return (
    <>
      <div className="flex" style={{ marginTop: "150px" }}>
        <div className="flex  Column primary">
          <p className="Tag">🧑‍💻 Skills and Experience</p>
          <h2>Technologies and skills</h2>
          <p className="tertiary" style={{ marginTop: "40px" }}>
            Tech I use every day
          </p>
          <div style={{display:"flex",flexWrap:'wrap',justifyContent:"center"}}>
            <img
              src={REACT}
              style={{ height: "35px", width: "35px", margin: "15px" }}
              alt="icon"
              draggable={false}
            />
            <img
              src={HTML}
              style={{ height: "35px", width: "35px", margin: "15px" }}
              alt="icon"
              draggable={false}
            />
            <img
              src={JAVASCRIPT}
              style={{ height: "35px", width: "35px", margin: "15px" }}
              alt="icon"
              draggable={false}
            />
            <img
              src={GIT}
              style={{ height: "35px", width: "35px", margin: "15px" }}
              alt="icon"
            />
            <img
              src={GITHUB}
              style={{ height: "35px", width: "35px", margin: "15px" }}
              alt="icon"
              draggable={false}
            />
            <img
              src={FIGMA}
              style={{ height: "35px", width: "35px", margin: "15px" }}
              alt="icon"
              draggable={false}
            />
            <img
              src={JAVA}
              style={{ height: "35px", width: "35px", margin: "15px" }}
              alt="icon"
              draggable={false}
            />

            <img
              src={TYPESCRIPT}
              style={{ height: "35px", width: "35px", margin: "15px" }}
              alt="icon"
              draggable={false}
            />
          </div>
          <p className="tertiary"> Other tech I use </p>
          <div>
            <img
              src={NODE}
              style={{ height: "35px", width: "35px", margin: "15px" }}
              alt="icon"
              draggable={false}
            />
            <img
              src={PYTHON}
              style={{ height: "35px", width: "35px", margin: "15px" }}
              alt="icon"
              draggable={false}
            />
            <img
              src={SQL}
              style={{ height: "35px", width: "35px", margin: "15px" }}
              alt="icon"
              draggable={false}
            />
            <img
              src={MONGO}
              style={{ height: "35px", width: "35px", margin: "15px" }}
              alt="icon"
              draggable={false}
            />
          </div>
        </div>
      </div>
    </>
  );
}
