import "./Landing.css";
import Avatar from "../../../assets/AVATAR.webp";
import FACEBOOK from "../../../assets/facebook.svg";
import INSTAGRAM from "../../../assets/instagram.svg";
import GITHUB from "../../../assets/github.svg";
export default function Landing() {
  return (
    <>
      <div className="Wrapper">
        <div id="LandingContainer">
          <div id="LandingNameContainer">
            <h2 className="Tag">🙏 Namaste !</h2>
            <p id="LandingName">Sagar Bhusal</p>
            <p id="LandingPosition">Full-Stack developer . UI developer </p>
            <div id="SocialContainer">
              <a
                href="https://github.com/sagarbhusal01"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={GITHUB} draggable={false} alt="Github logo" />
              </a>
              <a
                href="https://www.facebook.com/sagarbhusal01"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={FACEBOOK} draggable={false} alt="Facebook logo" />
              </a>
              <a
                href="https://www.instagram.com/sagar__bhusal_/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={INSTAGRAM} draggable={false} alt="Instagram logo" />
              </a>
            </div>
          </div>
          <div>
            <img
              src={Avatar}
              id="AvatarImage"
              draggable={false}
              alt="Sagar Bhusal's Avatar"
            />
          </div>
        </div>
      </div>
    </>
  );
}
