import "./Aboutme.css";
import MYIMAGE from "../../../assets/me.webp";
import { TotalDateAsProgrammer } from "../../../Data";
export default function Aboutme() {
  return (
    <>
      <div className="flex Align Justify">
        <div id="aboutme">
          <div>
            <img alt="Sagar Bhusal" src={MYIMAGE} id="meimg" />
          </div>
          <div id="mydetails">
            <p className="Tag">🧐 About me</p>
            <h1>Sagar Bhusal</h1>
            <p>
              👋 My name is Sagar Bhusal And I completed my studies from Kalika
              Manavgyan school
              <br />
              in the faculty of computer engineering
            </p>
            <p>
              👨‍💻 For over {TotalDateAsProgrammer} years developing and
              programming interfaces with JavaScript, React JS,
              <br /> react native and Java
            </p>
            <p>
              💡 Interests in Full-Stack development with Spring-boot, React,
              React Native, and UX/UI Design.
            </p>
            <p>🚀 Trying to be a little better than yesterday every day.</p>
          </div>
        </div>
      </div>
    </>
  );
}
