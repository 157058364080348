import "./Portfolio.css";
import JAVA from "../../../assets/JAVA.webp";
import EXPO from "../../../assets/EXPO.webp";
import SEEMORE from "../../../assets/Seemore.svg";
import { Link } from "react-router-dom";
export default function Portfolio() {
  return (
    <>
      <div className="flex" style={{ marginTop: "50px" }} id="portfolio">
        <div className="flex  Column">
          <p className="Tag">🔗 Portfolio</p>
          <h1 className="primary">Works and projects</h1>
        </div>
      </div>
      <div className="projectscontainer flex">
        <div className="flex projectslayout">
          {/* <!-- Docterr Card --> */}
          <div className="project">
            <a
              // href="https://github.com/sagarbhusal01/malawardevi-mahila-samuha"
              href="#portfolio"
            >
              <h3 className="primary">Mahila Samuha App</h3>
              <p className="tertiary">
                Mahila samuha mobile app was a gift to malawardevi mahila samuha
                at their 2nd anniversary program.
              </p>
              <div className="flex">
                <p className="Tag" style={{ marginRight: "20px" }}>
                  react-native
                </p>
                <p className="Tag">Expo</p>
              </div>
              <div
                className="flex"
                style={{ marginBottom: "30px", marginTop: "20px" }}
              >
                <img
                  src={EXPO}
                  draggable={false}
                  alt="Docterr project link"
                  style={{
                    height: "150px",
                    width: "150px",
                    borderRadius: "10px",
                  }}
                />
              </div>
            </a>
          </div>
          {/* <!-- Table builder --> */}
          <div className="project">
            <a
              href="https://github.com/sagarbhusal01/TableBuilder"
              target="_blank"
              rel="noopener noreferrer"
            >
              <h3 className="primary">Java Table Builder</h3>

              <p className="tertiary">
                A Java Table Builder can be used to make tables in Java CLI
                environment with various table options to chose from.
              </p>
              <div className="flex">
                <p className="Tag" style={{ marginRight: "20px" }}>
                  JAVA
                </p>
                <p className="Tag">CLI</p>
              </div>
              <div
                className="flex"
                style={{ marginBottom: "30px", marginTop: "20px" }}
              >
                <img
                  src={JAVA}
                  draggable={false}
                  alt="Java TableBuilder project link"
                  style={{
                    height: "150px",
                    width: "150px",
                    borderRadius: "10px",
                  }}
                />
              </div>
            </a>
          </div>
          {/* <!-- YAML Reader --> */}
          <div className="project">
            <a
              href="https://github.com/sagarbhusal01/YAML-Editor-For-Java"
              target="_blank"
              rel="noopener noreferrer"
            >
              <h3 className="primary">YAML Editor for java</h3>

              <p className="tertiary">
                A YAML Editor for Java is a tool that can perform read and write
                operation on .yaml file, can return data into hash map.
              </p>
              <div className="flex">
                <p className="Tag" style={{ marginRight: "20px" }}>
                  JAVA
                </p>
                <p className="Tag">CLI</p>
              </div>
              <div
                className="flex"
                style={{ marginBottom: "30px", marginTop: "20px" }}
              >
                <img
                  src={JAVA}
                  draggable={false}
                  alt="YAML Reader project link"
                  style={{
                    height: "150px",
                    width: "150px",
                    borderRadius: "10px",
                  }}
                />
              </div>
            </a>
          </div>
          {/* <!-- snacks  --> */}
          <div className="project">
            <a
              href="https://snack.expo.dev/@sagarbhusal01/fingerprint-authentication"
              target="_blank"
              rel="noopener noreferrer"
            >
              <h3 className="primary">Fingerprint-authentication</h3>

              <p className="tertiary">
                Its a small snack file to demonstrate the
                fingerprint-authentication system in expo react native
              </p>
              <div className="flex">
                <p className="Tag" style={{ marginRight: "20px" }}>
                  JS
                </p>
                <p className="Tag">React-native</p>
              </div>
              <div
                className="flex"
                style={{ marginBottom: "30px", marginTop: "20px" }}
              >
                <img
                  src={EXPO}
                  draggable={false}
                  alt="Expo snacks project link"
                  style={{
                    height: "150px",
                    width: "150px",
                    borderRadius: "10px",
                  }}
                />
              </div>
            </a>
          </div>
        </div>
      </div>
      <div
        className="flex "
        style={{ justifyContent: "flex-end", marginRight: "5%" }}
      >
        <Link to={"/seemore"}>
          <div className="Tag flex">
            See All
            {"\u2007"}
            <img src={SEEMORE} draggable={false} alt="see more  link" />
          </div>
        </Link>
      </div>
    </>
  );
}
