export const ProfessionalCareer = [
  {
    Title: "Worked as a front end developer ( intern )",
    Description:
      "  Developing differernt front end interface for the company that I worked on. Using the react framework, I there created a perfect UI for a Dairy management website",
    FinishTime: "300 hours",
    StartDate: "mars, 2020",
  },
  {
    Title: "Worked as a full stack developer",
    Description:
      " I led a team of developers to develop a mobile app that can be used for booking appointment with doctors. Using react native framework and firebase a perfect app was developed.",
    FinishTime: "300 hours",
    StartDate: "October, 2022",
  },
  {
    Title: "Worked as a junior IT Technician",
    Description:
      "I worked in an IT consultant in butwal, Nepal as a IT technician where I was assigned to manage network configuration and Security system installation.",
    FinishTime: "September, 2023",
    StartDate: "May, 2023",
  },
];

export const AcademicCareer = [
  {
    Title: "Graduated High school ",
    Description:
      "After getting my Secondary Education Examination ( SEE ). studied at Kalika manavgyan secondary school for 2 years to complete my diploma in computer engineering.",
    FinishTime: "September, 2023",
    StartDate: "may, 2021",
  },
  {
    Title: "Completed my school level.",
    Description:
      "After my eight standard I went to Haraiya Secondary School for my diploma in computer engineering. I studied there for my best two years.",
    FinishTime: "May, 2021",
    StartDate: "April, 2019",
  },
];

const DateAsProgrammer = new Date(2019, 1, 1);

const DateAsDesigner = new Date(2021, 1, 1);

function calculateDateDifference(startDate: Date, endDate: Date): number {
  // Get the time difference in milliseconds
  const timeDifference = endDate.getTime() - startDate.getTime();

  // Convert the time difference from milliseconds to days
  const dayDifference = timeDifference / (1000 * 365 * 3600 * 24);

  return parseFloat(dayDifference.toFixed(1));
}

export const TotalDateAsProgrammer = calculateDateDifference(
  DateAsProgrammer,
  new Date()
);

export const TotalDateAsDesigner = calculateDateDifference(
  DateAsDesigner,
  new Date()
);

// // Example of use:
// var d = date_units_diff(new Date(2010, 0, 1, 0, 0, 0, 0), new Date()).slice(0,-2);
// document.write("In difference: 0 days, 1 hours, 2 minutes.".replace(
//    /0|1|2/g, function (x) {return String( d[Number(x)] );} ));
