import "./NavBar.Style.css";
import LOGO from "../../assets/Logo.svg";
import HAMBURGER from "../../assets/hamburger.webp";
import { Link } from "react-router-dom";
export default function NavBar() {
  const OverlayOpen = () => {
    var a = document.getElementById("Overlay");
    if (a) {
      a.style.height = "100%";
    }
  };

  const OverlayClose = () => {
    var a = document.getElementById("Overlay");
    if (a) {
      a.style.height = "0%";
    }
  };

  // Keystroke eventlistner
  document.addEventListener("keydown", (event) => {
    if (event.key === "Escape") {
      OverlayClose();
    }
    if (event.code === "Space") {
      event.preventDefault();
      OverlayOpen();
    }
  });
  return (
    <>
      <div className="flex Align">
        <div id="NavContainer" className="flex">
          <div>
            <Link to="/">
              <img src={LOGO} draggable={false} alt="Portfolio's Logo" />
            </Link>
          </div>
          <div id="DesktopNavItems">
            <ul className="flex" id="NavItems">
              <li>
                <Link to="/">Home</Link>
              </li>

              <li>
                <a href="https://blog.sagarbhusal01.com.np">Blog</a>
              </li>
              <li>
                <a href="https://photography.sagarbhusal01.com.np">
                  Photography
                </a>
              </li>
            </ul>
          </div>
          <button id="MobileNav" onClick={() => OverlayOpen()}>
            <img src={HAMBURGER} id="HamburgerImage" alt="Hamburger menu" />
          </button>
        </div>
      </div>
      <div id="Overlay" className="flex Align Justify Column">
        <button onClick={() => OverlayClose()} id="CloseButton">
          &times;
        </button>
        <ul className="flex Column" id="MobileNavItems">
          <li>
            <Link to="/" onClick={() => OverlayClose()}>
              Home
            </Link>
          </li>
          <li>
            <a
              href="https://blog.sagarbhusal01.com.np"
              onClick={() => OverlayClose()}
            >
              Blog
            </a>
          </li>
          <li>
            <a
              href="https://photography.sagarbhusal01.com.np"
              onClick={() => OverlayClose()}
            >
              Photography
            </a>
          </li>
        </ul>
      </div>
    </>
  );
}
