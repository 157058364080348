import { Routes, Route, HashRouter } from "react-router-dom";
import Home from "./Pages/Home/Home.Main";
import SeeMore from "./Pages/SeeMore/SeeMore";
export default function Navigation() {
  return (
    <HashRouter basename="/">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/seemore" element={<SeeMore />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </HashRouter>
  );
}
