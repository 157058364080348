import NavBar from "../../Components/NavBar/NavBar.Main";
import "./SeeMore.css";
import { AllProjects } from "./AllProjects";
import EXPO from "../../assets/EXPO.webp";
import JAVA from "../../assets/JAVA.webp";
import JAVASCRIPT from "../../assets/javascript_COLORED.svg";
import CSHARP from "../../assets/csharp.svg"
export default function SeeMore() {
  const DecideImage = (ImageName: string) => {
    switch (ImageName.toUpperCase()) {
      case "JAVA":
        return JAVA;

      case "EXPO":
        return EXPO;

      case "JAVASCRIPT":
        return JAVASCRIPT;

      case "CSHARP":
        return CSHARP;
    }
  };
  return (
    <>
      <NavBar />
      <div className="flex">
        <div className="appsdrawer">
          <div className="flex projectslayout">
            {AllProjects.map((names: any, key: number) => {
              return (
                <>
                  <div className="project">
                    <a
                      href={names?.Link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <h3 className="primary">{names?.Topic}</h3>
                      <p className="tertiary">{names?.Description}</p>
                      <div className="flex">
                        <p className="Tag" style={{ marginRight: "20px" }}>
                          {names?.TagOne}
                        </p>
                        <p className="Tag">{names.TagTwo}</p>
                      </div>
                      <div
                        className="flex"
                        style={{ marginBottom: "30px", marginTop: "20px" }}
                      >
                        <img
                          src={DecideImage(names.ImageName)}
                          alt={names.ImageName}
                          style={{
                            height: "150px",
                            width: "150px",
                            borderRadius: "10px",
                          }}
                        />
                      </div>
                    </a>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
