import Aboutme from "../../Components/HomeComponents/Aboutme/Aboutme";
import Career from "../../Components/HomeComponents/Career/Career";
import ContactMe from "../../Components/HomeComponents/ContactMe/ContactMe";
import Experience from "../../Components/HomeComponents/Experience/Experience";
import Landing from "../../Components/HomeComponents/Landing/Landing";
import Portfolio from "../../Components/HomeComponents/Portfolio/Portfolio";
import TechnologyUsed from "../../Components/HomeComponents/TechnologyUsed/TechnologyUsed";
import HorizontalLine from "../../Components/HorizontalLine";
import NavBar from "../../Components/NavBar/NavBar.Main";

export default function Home() {
  return (
    <>
      <NavBar />
      <Landing />
      <HorizontalLine />
      <Experience />
      <HorizontalLine marginTop={40} />
      <Aboutme />
      <HorizontalLine marginTop={60} />
      <TechnologyUsed />
      <HorizontalLine marginTop={60} />
      <Portfolio />
      <HorizontalLine marginTop={60} />
      <Career/>
      <HorizontalLine marginTop={60} />
      <ContactMe/>
    </>
  );
}
